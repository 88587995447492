
  
  .crop-container {
    width: 100%;
    height: 400px;
    position: relative;
  }
  
  .controls {
    margin: auto;
    width: 50%;
    height: 80px;
    display: flex;
    align-items: center;
    margin-left: 43%;
  }
  .controls button {
    align-items: center;
  }
  
  .slider {
    padding: 22px 0px;
  }
  