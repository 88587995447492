.red {
    background-color: rgba(15, 96, 255, 1);
    color:white;
    border-radius: 10px !important;
    font-size: 12px;
    font-weight:700;
    display: flex;
    justify-content: center;
letter-spacing: 1px;
    align-items: center;
    height: 32px !important;
    
  
}

.save {
    background-color: rgba(72, 187, 120, 1);
    color:white;
    font-size: 16px;
    font-weight:600;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-right: 20px;
    padding: 10px 20px;
}