@font-face {
  font-family: "Urbanist";
  src: url("assets/fonts/font-urbanist/Urbanist-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("assets/fonts/font-urbanist/Urbanist-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("assets/fonts/font-urbanist/Urbanist-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Devamında diğer font stilleri ve ağırlıkları için benzer tanımlamalar... */

@font-face {
  font-family: "Urbanist";
  src: url("assets/fonts/font-urbanist/Urbanist-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("assets/fonts/font-urbanist/Urbanist-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("assets/fonts/font-urbanist/Urbanist-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("assets/fonts/font-urbanist/Urbanist-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

* {
  font-family: "Urbanist", sans-serif;
}

body {
  background-color: rgba(228, 226, 226, 0.25) !important;
  margin: 0;
}
.ant-layout-sider-trigger{
  position: static !important;
border-radius: 30px !important;

position: relative;

.anticon svg{
  position: fixed !important;
  bottom: 5% !important;
  left: 25px !important;
  border:2px solid rgba(255, 100, 100, 1);
  border-radius: 100px;
  padding: 5px;
  width: 27px !important;
  height: 27px !important;
  color:black 

  
}
}
.scrollbar-container{
border-radius: 33px !important;
}
.header-logo {
  img {
    padding: 10px;
    height: 65px;
    width: 200px;
    object-fit: contain;
  }

}
.ant-menu-title-content div {
  z-index: 0 !important;
}

// .ant-layout-sider-trigger {
//   line-height: 33px !important;
// }

@media screen and (max-width: 768px) {
 .ant-layout-sider-children{
  margin-top: -180px !important;
 }
  
}