@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.profileName {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
    font-size: 15px;
    color:"black"
}
.profileType {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
        font-size: 13px;
        color:gray;
        
}

@media  screen and (max-width:768px) {
  .logo{
    position: relative;
    left: -50%;
margin-top: 10px;
    img {
      width: 100px !important;
      height: 50px !important;

    }


  }
  .profileName{
    font-size: 12px !important;
  }
  .profileType{
    font-size: 10px !important;
  }
  .profile-icon{
    width: 30px !important;
    height: 30px !important;
  }
  .main-content {
    left: 0px !important;
  }
  
}