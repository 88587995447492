@import "../../../../mixins.scss";

/////////////////////////////////////////////////////////////////////////////////////////
/* MENU COMPONENT */
.menu {
  background: $white !important;
  color: $text;
  transition: none !important;
  font-size: rem(15);
  margin-top: 96px;
  border-top-right-radius: 33px;


  .menuOuter {
    position: sticky;
    top:0;
    height: 100%;
    width: inherit;
    max-width: inherit;
    margin-top: -70px;
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* LOGO */
  .logoContainer {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 rem(28);

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: rem(24);
      white-space: nowrap;
      width: 100%;
    }
    .name {
      font-size: rem(20);
      font-weight: 700;
      color: $black;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90%;
      display: block;
    }
    .descr {
      margin-left: rem(13);
      padding-left: rem(13);
      height: rem(38);
      line-height: rem(38);
      border-left: 1px solid $gray-2;
      font-size: rem(14);
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* ICON */
  .icon {
    text-align: center;
    position: absolute;
    left: 0px;
    width: 20px;
    top: 11px;
    margin: 0 !important;
    line-height: 1 !important;
    color: rgba(153, 153, 153, 1);
    font-size: rem(17);
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* COLLAPSE TRIGGER BUTTON */
  :global(.ant-layout-sider-trigger) {
    background: #fff;
    color: $text;
    transition: none;
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* ANTD MENU CUSTOMIZATION */
  .navigation {
    padding: 0 ;
    padding-left: 20px;
    transition: none !important;
    background: transparent;
    border-right: none;
    color: $text;
    margin-top: 30px;

    .title {
      font-size: 18px;
      font-weight: 500;
      margin-left: 10px;
    }

    :global(.ant-menu-submenu > .ant-menu) {
      background: transparent;
    }

    :global(.ant-menu-submenu-arrow) {
      opacity: 1;
 
    }

    :global(.ant-menu-inline .ant-menu-item),
    :global(.ant-menu-inline .ant-menu-submenu-title) {
      height: 25px;
      margin-top: 15px !important;
      margin-bottom: 15px !important;
      width: 100%;
      left: -4px;
    }

    :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      right: 70px;

    }

    :global(.ant-menu-item-group-title) {
      color: $gray-4 !important;
      font-size: rem(12);
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    :global(.ant-menu-submenu-title) {
      margin-bottom: 0 !important;
      transition: none !important;
      background: transparent !important;
      color: rgba(153, 153, 153, 1);

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-submenu) {
      border-radius: 0 !important;
      will-change: transform;
    }

    :global(.ant-menu-item) {
      margin-bottom: 4px !important;
      border-radius: 0 !important;
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
      > span {
        color: rgba(173, 173, 173, 1);
      }

      > a {
        color: rgba(173, 173, 173, 1);

        &:hover {
          color: $primary;

          .icon {
            color: $primary;
          }
        }
      }
    }

    :global(.ant-menu-submenu-selected) {
      :global(.ant-menu-submenu-title),
      .icon {
        color: #E30613 ;
      }
    }

    :global(.ant-menu-submenu-active) {
      .title {
        color: #E30613 ;
      }
      .icon {
        color: #E30613 ;
      }
    }

    :global(.ant-menu-submenu-open),
    :global(.ant-menu-submenu-active),
    :global(.ant-menu-item-active) {
      background: #fff !important;
      .icon {
        color: #E30613 ;
      }
    }

    :global(.ant-menu-item-selected) {
      background: transparent !important;
      position: relative;
      .title {
        color: #E30613 ;
      }
     
      &:before {
        content: "";
        display: block;
        width: 2px;
        height: 100%;
        border-radius:31px ;
        position: absolute;
        right: -0px;
        top: 10%;
        background: #E30613 ;
      }
      .icon {
        color: #E30613 ;
      }

      > a {
        color: $dark-gray-2 !important;

        .icon {
          color: #E30613  !important;
        }
      }

      &:after {
        display: none;
      }
    }

    :global(.ant-menu-item-active) {
      > span {
        color: #E30613 !important;
      }
    }

    :global(.ant-menu-item-disabled) {
      > span {
        color: $gray-4;
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* COLLAPSED STYLES */
  &:global(.ant-layout-sider-collapsed) {
    @at-root {
      :global(.ant-menu-inline-collapsed-tooltip) .icon {
        display: none;
      }
    }

    :global(.badge) {
      position: absolute;
      z-index: 1;
      top: 3px;
      right: 3px;
    }

    .title,
    .name,
    .line,
    .descr {
      display: none;
    }

    :global(.ant-menu-item-group) {
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        background: $border;
      }
    }

    :global(.ant-menu-item-group-title) {
      visibility: hidden;
    }

    :global(.ant-menu-inline-collapsed-tooltip) .icon {
      display: none;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      padding-right: 0 !important;

      .icon {
        right: rem(15);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* BANNER COMPONENT */
  .banner {
    padding: rem(20);
    background: $gray-1;
    border-radius: 3px;
    margin: rem(20);
    overflow: hidden;
    border: 1px solid $border;
  }

  :global(.ant-menu-inline-collapsed) + .banner {
    display: none;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
/* SETTINGS */
.unfixed {
  .menuOuter {
    position: relative;
  }
}

.shadow {
  box-shadow: 0 0 100px -30px rgba(57, 55, 73, 0.3);
  z-index: 1;
}

/////////////////////////////////////////////////////////////////////////////////////////
/* GRAY THEME */
.gray {
  background: $gray-1 !important;
}

/////////////////////////////////////////////////////////////////////////////////////////
/* DARK THEME */
.dark {
  color: $dark-gray-2;
  background: $dark-gray-6 !important;

  .icon {
    color: $dark-gray-2;
  }

  .logoContainer {
    .name {
      color: $white;
    }
    .descr {
      color: $dark-gray-2;
      border-left: 1px solid $dark-gray-4;
    }
  }

  .banner {
    background: $dark-gray-4;
    border: 1px solid $dark-gray-4;
  }

  :global(.ant-layout-sider-trigger) {
    background: lighten($dark-gray-6, 5%);
  }

  .navigation {
    :global(.ant-menu-item-group-title) {
      color: darken($dark-gray-3, 10%) !important;
    }

    :global(.ant-menu-submenu-title) {
      color: $dark-gray-2;

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-item) {
      > span {
        color: $dark-gray-2;
      }
      > a {
        color: $dark-gray-2;

        &:hover {
          color: $primary;

          .icon {
            color: $primary;
          }
        }
      }
    }

    :global(.ant-menu-submenu-open),
    :global(.ant-menu-submenu-active),
    :global(.ant-menu-item-active) {
      background: #fff !important;
    }

    :global(.ant-menu-item-active) {
      > span {
        color: $primary;
      }
      &:global(.ant-menu-item-selected) {
        background: $primary !important;
      }
    }

    :global(.ant-menu-item-disabled) {
      > span {
        color: $dark-gray-3;
      }
    }
  }

  &:global(.ant-layout-sider-collapsed) {
    :global(.ant-menu-item-group) {
      &:after {
        background: $dark-gray-4;
      }
    }
  }
}
