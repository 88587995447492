@import 'css/layout.scss';
@import 'css/utils.scss';
@import 'css/router.scss';


        
.trial-balance {
    .title {
      text-align: center;
      font-size: 20px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      margin: 10px 0;
      font-weight: bold;
    }
    table {
      border-collapse: separate;
      border-spacing: 0px;
      width: 100%;
      text-align: center;
      box-shadow: 0 0 5px 1px #d5d5d5;
      border-radius: 10px;
      .bold {
        font-weight: bold;
      }
      .fontLg {
        font-size: 16px;
      }
      .alignLeft {
        text-align: left;
      }
      .alignRight {
        text-align: right;
      }
      .borderBottom {
        border-bottom: 1px solid #eee;
      }
      .debt {
        color: #d43f3a;
      }
      .due {
        color: #1c9404;
      }
      tr {
        text-align: center;
        td {
          text-align: center;
          border: 1px solid #eee;
          padding: 5px 5px;
        }
        td.sequence {
          width: 50px;
        }
      }
    }
  }

  .ant-modal-title{
    margin: 0;
    color: #595c97;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 22px;
    word-wrap: break-word;
  }
