.red{
    background-color: rgba(15, 96, 255, 1);
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 5px 15px;
    font-size: 12px;
    height: 32px;
    
}

.header-class{
    text-align: left !important;
}
.buttons:hover{
background: red;
}