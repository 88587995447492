@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~c3/c3.min.css";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

@import "components/kit/vendors/antd/style";
@import "components/kit/vendors/bootstrap/style";
@import "components/kit/vendors/perfect-scrollbar/style";
@import "components/kit/vendors/nprogress/style";
@import "components/kit/vendors/chartist/style";
@import "components/kit/vendors/chartist-tooltip-plugin/style";
@import "components/kit/vendors/jvectormap/style";
@import "components/kit/vendors/react-sortable-tree/style";
@import "components/kit/vendors/react-draft-wysiwyg/style";

@import "components/kit/core/style"; // Clean UI KIT styles
@import "components/cleanui/styles/style"; // Clean UI styles
// resources/fonts/font-feathericons/dist/feather.css
@import "assets/fonts/font-feathericons/dist/feather.css";

.ant-form-item-control-input-content {
  display: flex;
}

// @font-face {
//   font-family: SansProLight;
//   src: url("/fonts/SourceSansPro-Light.ttf");
// }

// @font-face {
//   font-family: SansProRegular;
//   src: url("/fonts/SourceSansPro-Regular.ttf");
// }

.treeDot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

main {
  
}
:where(.css-dev-only-do-not-override-kghr11).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{

  background:transparent !important
}
.mainHeader {
  font-weight: bold !important;
  font-size: 16px !important;
  color: black !important;
}

.mainHeaderContent {
  font-weight: bold !important;
  font-size: 15px !important;
}

.ant-input-clear-icon {
  margin-right: 5px !important;
}

.container {
  background: rgba(255, 255, 255, 255) none repeat scroll 0% 0%;
  margin: 5px 0px;
  padding: 8px;
  box-shadow: 0 0 1px 1px #aaa;
  margin-bottom: 7px;
}

// override default variables for BaseTable
$table-prefix: BaseTable;
@import "~react-base-table/es/_BaseTable.scss";

.#{$table-prefix}__header-cell:first-child {
  padding-left: 7.5px !important;
}

.#{$table-prefix} {
  .#{$table-prefix}__row-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .#{$table-prefix}__header-cell-text {
    width: 100% !important;
    text-align: left !important;
    color: rgba(160, 174, 192, 1);
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }

  .#{$table-prefix}__row:nth-child(odd) {
    background-color: white;
  }

  .#{$table-prefix}__body {
    overflow-x: hidden !important;
  }

  .#{$table-prefix}__row-cell {
    text-align: center !important;

    div {
      width: 100%;
    }
  }

  .#{$table-prefix}__sort-indicator {
    position: absolute;
    right: 0;
  }

  .rowStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  //Blue
  .rowStyle1 {
    background: #e9f8ff;
  }

  //Green
  .rowStyle2 {
    background: #f5fff5;
  }

  //Yellow
  .rowStyle3 {
    background: #fffed0;
  }

  //Orange
  .rowStyle4 {
    background: #ffcfa7;
  }

  //Red
  .rowStyle5 {
    background: #fff2f2;
  }

  //Lila
  .rowStyle6 {
    background: #ffe5ff;
  }
}

.card-header-flex {
  justify-content: space-between;
}

.language-list-item {
  cursor: pointer;
  margin: 5px;
}

.language-list-item:hover {
  text-decoration: underline;
}

.language-selected {
  margin: 20px;
}

.hr-text {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hr-text hr {
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $dark-gray-1;
  margin: 1em 0;
  padding: 0;
}

.hr-text p {
  margin-inline: 20px;
  white-space: nowrap;
}

.hr-text span {
  margin-inline: 15px;
  white-space: nowrap;
  font-size: 15px;
  color: $dark-gray-1;
}

.ant-input {
  border-color: $light !important;
}

.ant-select {
  border-color: $light !important;
}

.ant-input {
  border-color: $light !important;
}

.ant-input-lg {
  border-color: $light !important;
}

.ant-form-item-control-input-content {
  border-color: $light !important;
}

.border-style {
  border-color: $light !important;
}

.border-style-upload {
  border-color: $white !important;
  padding: 0;
}

.generate-password-icon {
  transform: rotate(360deg);
  transition: all 1s ease;
}

.ant-select-selector {
  border: 1px solid #f2f4f8 !important;
}

.multi-file-input-no-margin {
  .ant-card-body {
    padding-left: 0 !important;
  }
}

.image-modal-img {
  height: 72vh;
  width: 100%;
  object-fit: contain;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: "center";
}

.multi-file-input-no-margin {
  .ant-card-body {
    padding-left: 0 !important;
  }
}

.form-nullable-radio {
  margin-top: -20px !important;
  height: 80px;

  .ant-form-item-label {
    padding-bottom: 2px !important;

    label {
      font-size: 12px !important;
    }
  }
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-btn {
  height: 40px !important;
}

.input-uppercase {
  .ant-input,
  .ant-select-selection-item {
    text-transform: uppercase !important;
  }

  text-transform: uppercase !important;
}

.text-transform-initial {
  text-transform: initial !important;
}

.grid-total-record-splitter {
  font-size: 16px;
  margin: 0 10px;
}

.radius-bg {
  padding-top: 5px;
  background: #dcdcdc;
  border-radius: 50px;
  width: 30px;
  height: 30px;
}

.delivery-documents {
  .title {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .upload-form {
    .delivery-uploaded-documents {
      box-shadow: 0 0 5px 1px #a4a4a4;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 10px;

      .document-image {
        text-align: center;
        margin-bottom: 10px;

        img {
          width: 100%;
          max-width: 100px;
        }
      }

      .close-icon {
        float: right;
        cursor: pointer;
        color: #000;
      }
    }
  }

  .delivery-document {
    box-shadow: 0 0 5px 1px #a4a4a4;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;

    // min-height: 276px;
    img {
      margin-bottom: 5px;
      width: 100%;
      max-width: 90px;
      cursor: pointer;
    }

    .document-name {
      text-align: center;
      font-weight: bold;
      word-wrap: break-word;
      min-height: 21px;
      cursor: pointer;
    }

    .document-desc {
      text-align: center;
      word-wrap: break-word;
      min-height: 21px;
      cursor: pointer;
    }

    .close-icon {
      float: right;
      cursor: pointer;
      color: #000;
    }

    .edit-icon {
      float: left;
      cursor: pointer;
      color: rgb(206, 125, 4);
    }

    .order-image {
      margin-bottom: 5px;
      width: 100%;
      max-width: 200px;
      cursor: pointer;
    }
  }
}

.image-slider-container {
  position: relative;
  max-width: 400px;
  min-height: 300px;
  max-height: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.slider-image-container {
  position: relative;
  width: 100%;
}

.slider-image {
  width: 100%;
  height: 100%;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  /* Z-index değeri ekledik */
}

.custom-prev-arrow {
  left: 0;
}

.custom-next-arrow {
  right: 0;
}

.menu-topbar {
  flex: 1;
  height: 50px;
  min-width: 0;
  align-items: center;
  justify-content: flex-end;

  li {
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.range-picker-placeholder ::placeholder {
  color: black !important;
  font-size: 15px !important;
}

.ant-form-item-explain {
  // margin-left: 10px !important;
}

.header-profile > div {
  display: flex;
}

.header-profile::after {
  border-bottom: none !important;
}

.left-menu-content > ul {
  border-inline-end: none !important;
}

.site-layout-background__content p {
  color: rgba(18, 18, 18, 0.6);
  font-size: 14px;
}

.dashboard-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-top: 20;
}

.dashboard-icon {
  background-color: #fff;
  border-radius: 85px;
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #fde8e8;
}

.BaseTable__header-cell {
  background-color: white !important;
}

.table-grid-action {
  height: 20px !important;
}

.BaseTable__row {
  border-bottom: 1px solid rgba(226, 232, 240, 1) !important;
}

.ant-pagination > li {
  margin-inline-end: 2px !important;
}
.ant-pagination > li:not(.ant-pagination-item-active) {
  background-color: rgba(241, 242, 246, 1);
  color: rgba(139, 144, 154, 1);
}

.ant-pagination-item-active {
  background-color: #E30613  !important;
  color: #fff;
  border: 0 !important;
}

.ant-pagination > li svg {
  color: rgba(139, 144, 154, 1);
}

.sidebar-menu {
  background-color: #fff !important;
  position: fixed !important;
  z-index: 1000;
  height: 100vh;
}

.header-topbar 
{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
}

.header-topbar > ul {
  border: 0;
}

.grid-buttons button {
  height: 30px !important;
  box-shadow: none !important;
  border: 0 !important;
}

.form-items > div {
  width: 100%;
}

.ps {
  margin-top: 10px !important;
}
.main-content {
  position: absolute;
  top: 80px;
  right: 0;
}

:where(.css-dev-only-do-not-override-kghr11).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #E30613 !important;
  border-color: #E30613 !important;
}

:where(.css-dev-only-do-not-override-kghr11).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-kghr11).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #E30613 !important;
}