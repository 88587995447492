.float-label {
  position: relative;
  margin-bottom: 1px;
  width: 100%;
  
}

.ant-input-affix-wrapper:hover {
  z-index:unset !important;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 9px;
  transition: 0.2s ease all;
}

.label-date {
  font-size: 6px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: -10px;
  transition: 0.2s ease all;
}

.label-float  {
  top: -10px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  margin-top: -3px;
  z-index: 1;
}



.label-float-disabled  {
  top: -18px;
  font-size: 12px !important;
  background: transparent;
  padding: 0 4px;
  margin-left: -4px;
}
