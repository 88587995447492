
.imgContainer{
    transition: all 1s ease;
    &:hover{
        transition: all 1s ease;
        .file-actions-buttons{ 
            transition: all 1s ease;
            display: flex !important;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            bottom: 0%;
            left:-9%;
width: 126px !important;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 81%);
            border-radius: 0px 0px 10px 10px;

        }
    }
}
.file-actions-buttons{
    transition: all 1s ease;
    position: absolute;
    bottom: 5%;
    left: -10%;
    display: none !important;



}
.form-select{
    border:1px solid rgba(233, 238, 243, 1);
    border-radius: 5px;

}
